import React from 'react'
import ReactDOM from 'react-dom/client'
import Keycloak from 'keycloak-js'
import './index.css'
import App from './App'

export const keycloakURIFromHost = () => {
    const ourHostName = window.location.hostname
    const baseHost = ourHostName.split('.').slice(1).join('.')

    if (window.location !== window.parent.location) {
        // The page is in an iFrames
        return `https://authz.nc.${baseHost}/auth`
    } else {
        return `https://authz.${baseHost}/auth`
    }
}

export const keycloak = new Keycloak({
    realm: process.env.REACT_APP_OVERRIDE_REALM || 'staging-gwt',
    url: process.env.REACT_APP_OVERRIDE_KEYCLOAK_URI || keycloakURIFromHost(),
    clientId: 'demo-dash',
})

keycloak
    .init({ onLoad: 'login-required' })
    .then(() => {
        sessionStorage.setItem('kc', JSON.stringify(keycloak))
        sessionStorage.setItem('token', keycloak.token || '')
        const root = ReactDOM.createRoot(
            document.getElementById('root') as HTMLElement
        )

        root.render(
            <React.StrictMode>
                <App />
            </React.StrictMode>
        )
    })
    .catch(() => {
        //TODO: 'Will need a 500 "page"'. May we can push to some state here...
        console.error('Bruh')
    })

keycloak.onTokenExpired = () => {
    keycloak
        .updateToken(10)
        .then(() => {
            sessionStorage.setItem('token', keycloak.token || '')
        })
        .catch(() => {
            console.debug('Failed to refresh token')
        })
}
