import { useLocation } from 'react-router-dom'

export default function ImpFrame({
    path,
}: {
    path: string | undefined
}): React.ReactElement {
    const { search } = useLocation()
    window.addEventListener('message', function(event) {
        console.log('e', event)
    })
    return (
        <iframe
            className="imp-iframe"
            src={path + search}
            width="100%"
            height="100%"
            id="imp-iframe"
            title="imp-iframe"
        />
    )
}
