import React from 'react'
import Router from './Router'
import './App.css'

function App() {
    return (
        <div
            style={{ height: '100%', display: 'flex', flexDirection: 'column' }}
        >
            <Router />
        </div>
    )
}

export default App
