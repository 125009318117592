import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import ProfileImage from '../images/default-profile.png'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone'
import Avatar from '@mui/material/Avatar'
import { keycloak } from '../index'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import IconButton from '@mui/material/IconButton'
import { Box } from '@mui/material'

const Header: React.FC<{ logo: any }> = ({ logo }) => {
    const [userEmail, setUserEmail] = useState('')
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget)
    }
    const handleClose = () => {
        setAnchorEl(null)
    }

    useEffect(() => {
        const loadUserProfile = async () => {
            try {
                const userProfile = await keycloak.loadUserProfile()
                setUserEmail(userProfile?.email || '')
            } catch (error) {
                console.error('Error loading user profile:', error)
            }
        }

        loadUserProfile()
    }, [])

    return (
        <Box className="header">
            <Box className="header-left">
                <img
                    className="header-logo"
                    id={'logoImage'}
                    src={logo}
                    alt="Logo"
                />
            </Box>
            <Box className="header-center">
                {[
                    ['Dashboard', '/'],
                    ['Apps & Integrations', '/integrations'],
                    ['My Apps', '/myapps'],
                    ['Settings', '/settings'],
                ].map(([name, target]) => (
                    <NavLink
                        key={name}
                        to={target}
                        style={({ isActive }) => {
                            return {
                                color: 'black',
                                fontSize: '1.5rem',
                                textDecoration: 'none',
                                margin: '20px',
                                borderBottom: isActive
                                    ? '2px solid black'
                                    : 'none',
                            }
                        }}
                    >
                        {name}
                    </NavLink>
                ))}
            </Box>
            <Box className="header-right">
                <NotificationsNoneIcon
                    className="header-bell-icon"
                    fontSize="medium"
                />
                <Box className="header-user-email">{userEmail}</Box>
                <Avatar src={ProfileImage}></Avatar>
                <Box>
                    <IconButton aria-haspopup="true" onClick={handleClick}>
                        <MoreVertIcon
                            fontSize="medium"
                            className="header-dots-icon"
                        />
                    </IconButton>
                    <Menu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                    >
                        <MenuItem
                            key={'logout'}
                            onClick={() => keycloak.logout()}
                        >
                            Log out
                        </MenuItem>
                    </Menu>
                </Box>
            </Box>
        </Box>
    )
}

export default Header
